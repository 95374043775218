@import "node_modules/bootstrap/scss/bootstrap";

.webcam {

  &--container {
    position: relative;
    width: 100vw;

    video {
      width: 100vw;
    }
  }

  &--filter {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &--photo {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    left: 50%;
    padding: 20px;
    background-color: #2F45E1;
    border: 10px solid #FFF;
    border-radius: 50%;
    display: flex;
    margin-left: -64px;

    &:focus,
    &:active,
    &:hover {
      background-color: #2F45E1;
    }
  }

  &--filters {
    position: absolute;
    top: -100vh;
    left: 0;
    transition: 0.5s ease-in-out;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 3;
    width: 100%;
    overflow-y: auto;

    &--open {
      top: 0;
    }

    &--button {
      position: absolute;
      z-index: 3;
      top: 15px;
      right: 15px;
      border: 5px solid white;
      border-radius: 50%;
      padding: 0;

      img {
        width: 60px;
        height: 60px;
      }
    }

    &--back-button {
      position: absolute;
      z-index: 3;
      top: 15px;
      left: 15px;
      background-color: #2F45E1;
      border: 5px solid white;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      display: flex;

      svg {
        font-size: 2em;
        margin: auto;

        path {
          fill: #FFFFFF;
        }
      }
    }
  }

  &--loading {
    background-color: rgba(28, 175, 255, 1);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    &__active {
      display: flex;
    }

    &--content {
      display: block;
      width: 90vw;
      margin: auto;
      font-size: 1.75rem;
      text-align: center;
      font-weight: bold;
      color: white;

      p {
        margin: 0 0 15px;
      }
    }
  }
}

.photo {

  &--container {
    position: relative;
  }

  &--options {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        width: 50%;
        display: inline-block;
        text-align: center;
        padding: 20px 0;
        border: 1px solid rgba(0, 0, 0, 0.4);
        background-color: rgba(0, 0, 0, 0.4);
        color: white;

        a {
          display: block;
          width: 100%;
          color: white;
        }

        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.styles_Camera__Video_IOqliXwYi27n0wvse5HbM {

  video {
    transform: scaleX(-1);
  }
}

.styles_CameraButtons_ZEKENhDhqyd8sw0ff9em1 {
  position: absolute;
  bottom: 20px;
  z-index: 2;
  left: 50%;

  .styles_CameraButtons__TakePhoto_39jWdDEN3p_AXFhDJy9o5u {
    padding: 20px;
    background-color: rgba(28, 175, 255, 0.25);
    border: 10px solid #FFF;
    border-radius: 50%;
    display: flex;
    margin-left: -64px;

    &:focus,
    &:active,
    &:hover {
      background-color: rgba(28, 175, 255, 1);
    }
  }

  .styles_CameraButtons__ChangeCamera_1VNIrUIH17QDyB-BF0OqYb {
    display: none;
  }
}